import React from 'react';
import Logo from '@assets/chenmed.svg';
import { Helmet } from 'react-helmet-async';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import Layout from '../../components/layout';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import Segment from '../../components/segment';
import Seo from '../../components/seo';
import ImageText from '../../components/sections/imageText/imageText';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import LicensedPtSliderST from '../../components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import TestimonialGridST from '../../components/staticSections/TestimonialGridST/TestimonialGridST';
import Table from '../../components/sections/table/table';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import FaqST from '../../components/staticSections/FaqST/FaqST';
import CtaST from '../../components/staticSections/CtaST/CtaST';

const IndexPage = () => {
  return (
    <Layout
      lang="us"
      headerWitdh="header"
      topHeader
      text="Partnered with ChenMed"
      showFooter
      headerImage={
        <img className="logo--md margin-left--xs flex" alt="Chenmed" src={Logo} />
      }
    >
      {/* <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
        f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NCWLPMM');</script>`,
          }}
        />
      </Helmet> */}
      <Helmet>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
        f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NCWLPMM');`}</script>
      </Helmet>
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
      <Seo title="ChenMed" language="en" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedata4
        dataSection="hero_CTA"
        alt="blue shield of california"
        title="Treat joint and back pain from home"
        titleClassName="bold"
        text="Download the Joint Academy app to your phone and get started with a personalized exercise program today."
        textClassName=""
        // calldataGoal="Calendly Viewed"
        // callhref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        // callclassname="margin-top--md btn btn--primary btn--fullwidth btn--lg margin-bottom--xs btn--lgMob"
        // calltext="Get Started"
        downloadButton
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/g1osoik"
        downloadclassname="margin-top--md btn btn--fullwidth btn--lg btn--primary"
        downloadtext="GET STARTED"
        tagHeadline="Get a text to download the app"
        smsTag
        smsTagClassName="display--md"
        dataToken="eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzM1OTE1NTEsImp0aSI6ImViYTFlYTczLTczNjQtNGExMi04MGUyLTJiNjBhZDM0MDg0YSIsImFmZmlsaWF0ZV9uYW1lIjoiQ2hlbk1lZCBMTEMifQ.liNPfESgK8ViWJKqNuTzbc1yv-pCjbuDHR6-ZNqfTzA"
      />
      <ImageText
        imgSize="col--lg-6"
        Filename="chenmed@2x.png"
        alt="In-network with ChenMed"
        Position=""
        title="Partnered with ChenMed"
        text="Joint Academy is available to you at no cost as a ChenMed patient. Download the Joint Academy app to confirm your coverage or contact support for further assistance."
        download
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/g1osoik"
        downloadclassname="btn btn--primary btn--md btn--lgMob"
        downloadtext="get started"
      />
      <GetStartedST
        lang="us_en"
        renderDownloadButton
        ctaButtonText="Get Started"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/g1osoik"
      />
      <BgCardsTextST dataTheme="light" lang="us_en" />
      <SocialProofFeedST lang="us_en" />
      <TestimonialGridST lang="us_en" />
      <LicensedPtSliderST
        lang="us_en"
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/g1osoik"
        downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
        downloadtext="Get started today"
      />

      <Table
        title="Joint Academy vs. traditional treatment"
        text="Joint Academy is a digital treatment for chronic joint pain that might be beneficial you you compared to traditional face-to-face physical therapy available at clinics."
        images={[
          {
            alt: 'Price table large',
            Filename: 'price-table-large-free.png',
            className: 'display--sm',
          },
          {
            alt: 'Price table small',
            Filename: 'price-table-small-free.png',
            className: 'hide--sm',
          },
        ]}
        ctaButtonText="GET STARTED AT NO COST"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/g1osoik"
        subText={[
          {
            text: '**Average $30 copay per visit for an average of 4 visits per month.',
            className: '',
          },
        ]}
      />
      <OutcomeST lang="us_en" />
      <FaqST lang="us_en" />
      <CtaST
        lang="us_en"
        ctaDataGoal="Calendly Viewed"
        ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        ctaButtonText="Schedule Call"
      />
    </Layout>
  );
};

export default IndexPage;
